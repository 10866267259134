import fall25 from './spring25'
import spring25 from './spring25'

const state = {
  currentSite: 'fall25',
  previousSite: 'spring25'
}

const modules = {
  spring25,
  fall25
}

export default {
  state,
  modules,
  namespaced: true
}
