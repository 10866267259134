import { loadComponent } from '@/helpers/asyncLoaders'
import store from '@/store/store'

import {
  VUEX_USER_HAS_CONFERENCE_ASIAN,
  VUEX_USER_HAS_CONFERENCE_DOMESTIC,
  VUEX_USER_HAS_CONFERENCE_INTERNATIONAL
} from '@/store/constants/user'

const hasPerms = () => {
  return store.getters[VUEX_USER_HAS_CONFERENCE_ASIAN] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_DOMESTIC] ||
    store.getters[VUEX_USER_HAS_CONFERENCE_INTERNATIONAL]
}

export const routes = {
  path: 'fall25',
  name: 'fall25--main',
  meta: {
    conferenceClass: 'fall25'
  },
  component: loadComponent('GlobalConference/Fall25/Main.vue'),
  redirect: { name: 'fall25--landing' },
  children: [
    {
      path: 'load',
      name: 'fall25--load',
      component: loadComponent('GlobalConference/Fall25/Pages/Load.vue')
    },
    {
      path: 'landing',
      name: 'fall25--landing',
      component: loadComponent('GlobalConference/Fall25/Pages/Landing.vue'),
      beforeEnter: (to, from, next) => {
        if (!store.state.globalConference.fall25.loaded) {
          return next({ name: 'fall25--load' })
        }
        next()
      }
    },
    {
      path: 'categories',
      name: 'fall25--categories',
      component: loadComponent('GlobalConference/Fall25/Pages/Categories.vue'),
      meta: {
        eventCategory: 'fall25',
        title: 'Product Categories',
        navigation: [
          'Womens',
          'Mens',
          'Kids',
          'Unisex'
        ]
      }
    },
    {
      path: '',
      name: 'fall25--dashboard',
      component: loadComponent('GlobalConference/Fall25/Dashboard.vue'),
      beforeEnter: (to, from, next) => {
        if (hasPerms()) {
          next()
        } else {
          next('/')
        }
      },
      children: [
        {
          path: '',
          name: 'fall25--dashboard-default',
          redirect: { name: 'fall25--ads' }
        },
        {
          path: 'ads',
          name: 'fall25--ads',
          component: loadComponent('GlobalConference/Fall25/Pages/Advertising.vue'),
          meta: {
            eventCategory: 'fall25',
            title: 'Advertising',
            navigation: [
              'Marketing',
              'Womens',
              'Mens',
              'Kids',
              'Unisex',
              'Work',
              'BTS',
              'International',
              'Performance',
              'Social',
              'Radio',
              'Apparel'
            ]
          }
        },
        {
          path: 'sessions',
          name: 'fall25--sessions',
          component: loadComponent('GlobalConference/Fall25/Pages/Sessions.vue'),
          meta: {
            eventCategory: 'fall25',
            title: 'General Sessions'
          }
        }
      ]
    }
  ]
}

/* export const routes = {
  path: 'fall25',
  name: 'fall25--main',
  meta: {
    conferenceClass: 'fall25'
  },
  component: loadComponent('GlobalConference/Fall25/Main.vue'),
  redirect: { name: 'fall25--dashboard' },
  children: [
    {
      path: 'dashboard',
      name: 'fall25--dashboard',
      component: loadComponent('GlobalConference/Fall25/Dashboard.vue'),
    },

    {
      path: 'categories',
      name: 'fall25--categories',
      component: loadComponent('GlobalConference/Fall25/Pages/Categories.vue'),
      meta: {
        eventCategory: 'fall25',
        title: 'Product Categories',
        navigation: [
          'Womens',
          'Mens',
          'Kids',
          'Unisex'
        ]
      }
    },
  ]
} */
